/*
File Name: CaseStudyList.js
Author: 
Modified On: 
Description: To display list of case sudy
API's used:
1) `/case_study_list`
*/
import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Button,
	BackTop,
	Table,
	Space,
	Select,
} from "antd";
import "../../../styles/admin.css";
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { DeleteOutlined,EditOutlined, } from '@ant-design/icons';
import { useHistory } from "react-router";
const { Option } = Select;
const CaseStudyList = () => {
	const history = useHistory();
	const pagination = { showSizeChanger: true, };
	const [caseStudyData,setCaseStudyData]=useState([]);
	const [caseStudyDisplayData,setCaseStudyDisplayData]=useState([]);
	const [categoryID,setCategoryID]=useState(null);
	useEffect(() => {
		getCaseStudyList();
	}, []);
	// 
	const onCategoryChange= (value)=>{
		setCategoryID(value); 
		if(value==="0"){
			setCaseStudyDisplayData(caseStudyData);
		}else{
			let tempList=caseStudyData.filter((item)=>Number(item.category_id)===value);
			console.log("data",tempList);
			setCaseStudyDisplayData(tempList);
		}
	};
	const topTenColumns = [
		{
			title: 'Action',
			render: (record) => (
				<Space>
					<Button type="text" icon={<DeleteOutlined/>}></Button>
					<Button type="text" icon={<EditOutlined/>} onClick={()=>history.push({
						pathname : '/edit-case-study',
						state : { recordData:record } 
					} )}></Button>
				</Space>
			),
		},
		{
			title: 'Category',
			key: 'category_abbreviation',
			dataIndex: 'category_abbreviation',
			sorter: (a, b) => {
				a = a.category_abbreviation || '';
				b = b.category_abbreviation || '';
				return a.localeCompare(b);
			},
		},
		{
			title: 'Case Study',
			key: 'case_study_content',
			dataIndex: 'case_study_content',
			render: (record) => {
				return (<span dangerouslySetInnerHTML={{ __html: record }}/>);
			},
		},
	];
	//To get promocode list
	const getCaseStudyList=async()=>{
		fetchAdminApi(`/case_study_list`, "get").then(
			(res) => {
				if (res && res.data) {
					setCaseStudyData(res.data);
					setCaseStudyDisplayData(res.data);
				} else {
					setCaseStudyData([]);
					setCaseStudyDisplayData([]);
				}
			}
		);
	};
	return (
		<div className='gray-bg'>
			<div className="p-3">
				<Breadcrumb>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/masters-menu" });
					}}>Masters</a></Breadcrumb.Item>
					<Breadcrumb.Item>Case Study</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className='px-3'>
				<BackTop/>
				<Row gutter={[24, 24]} >
					<Col xs={24} sm={24} md={24} lg={24}>
						<Card size="small" title={<span className="text-uppercase">Case Study List</span>} 
							extra={
								<Space>
									<Select
										showSearch
										placeholder="Select category"
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										onChange={(e)=>{onCategoryChange(e);} }
										defaultValue="0"
									>
										<Option key="0" value="0">--Category--</Option>
										{constants.CategoryList &&
														constants.CategoryList.map((category) => (
															<Option key={category.category_id} value={category.category_id}>
																{category.category_name}
															</Option>
														))}
									</Select>
									<Button className="edit-butoon" onClick={()=>history.push({
										pathname : '/add-case-study',
										state : { categoryId:categoryID } 
									} )}>Add Case Study</Button>
								</Space>
							}
						>						
							<p>Displaying {caseStudyDisplayData.length} case studies.</p>
							<Table className="admin-gap-table" 
								locale={{ emptyText: 'No Records Found' }}
								dataSource={caseStudyDisplayData} 
								columns={topTenColumns}
								pagination={pagination}
							/>
						</Card>
					</Col>
				</Row> 
			</div>
		</div>
	);
};
export default CaseStudyList;
