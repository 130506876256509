/*
File Name: AddUniversity.js
Author: Dhiraj
Modified On: 21/01/2025
Description: To add a University
API's used:
1) admin/add_new_university
*/
import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	// Row,
	// Col,
	Card,
	Button,
	BackTop,
	Space,
	Select,
	Form,
	message,
	Input
} from "antd";
import "../../../styles/admin.css";
import {  fetchAdminApi , fetchApi, } from "../../../services/api";
import 'react-quill/dist/quill.snow.css';
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
const { Option } = Select;
const AddUniversity = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [listStates, setListStates] = useState([]);
	const formItemLayout = {
		labelCol: {
			xs: { span: 24, },
			sm: { span: 6, },
			lg: { span: 4, },
		}
	};
	//on Cancel button
	const onCancelBtn=()=>{
		form.resetFields();
		history.push({ pathname: "/university-master" });
	};
	const addUniversity=(values)=>{
		let payload={
			region_code:values.state,
			university_name:values.university
		};
		fetchAdminApi(`/add_new_university`, "post", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						history.push({ pathname: "/university-master" });
					} else {
						message.error(res.message);
					}
				} else {
					message.error(constants.AddUniversityFailed);
				}
			}
		);
	};
	const fetchStates = () => {
		fetchApi(`/states?country_code=US`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setListStates(res.data);
			} else {
				setListStates([]);
			}
		});
	};
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	useEffect(() => {
		fetchStates();
	}, []);
	return (
		<div className="div min-h-full">
			<div className="p-3">
				<Breadcrumb>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick={() => {
						history.push({ pathname: "/university-master" });
					}}>Universities</a></Breadcrumb.Item>
					<Breadcrumb.Item>{constants.AddUniversity}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<div className="p-4">
				<BackTop/>
				<div className="container mx-auto">
					<Card size="small" title={<span className="text-uppercase">{constants.AddUniversity}</span>}>
						<Form form={form} onFinish={addUniversity} {...formItemLayout}>
							<Form.Item
								label="State"
								name="state"
								rules={[
									{
										required: true,
										message: constants.SelectState,
									},
								]}
							>
								<Select
									showSearch
									placeholder={constants.SelectState}
									filterOption={selectFilterOption}
									allowClear
								>
									{listStates.map((ListState) => (
										<Option key={ListState.region_code} value={ListState.region_code}>
											{ListState.region_name}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="University"
								name="university"
								rules={[
									{
										required: true,
										message: constants.OtherUniversityRegistration,
									},
								]}
							>
								<Input/>
							</Form.Item>
							<div className="text-right">
								<Space>
									<Form.Item>
										<Button type="primary" htmlType="submit" className="navy-btn" >Submit</Button>
									</Form.Item>
									<Form.Item>
										<Button onClick={onCancelBtn}>Cancel</Button>
									</Form.Item>
								</Space>
							</div>
						</Form>
					</Card>
				</div>
			</div>
		</div>
	);
};
export default AddUniversity;